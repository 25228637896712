import logo from './logo.svg';
import './App.css';
import Home from './Components/Home';
import "react-google-reviews/dist/index.css";

function App() {
  return (
    <Home />
  );
}

export default App;
